import { Box } from '@mui/material';

const GlobalLoading = () => {
  return (
    <Box
      display={'grid'}
      sx={{ placeItems: 'center', animation: 'ease-in-out', animationDuration: 300 }}
      width={'100dvw'}
      height={'100dvh'}
    >
      <svg
        id="global-loader"
        width="270"
        height="200"
        viewBox="0 0 270 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_13780_49752)">
          <path
            d="M15.3645 123.119H29.3107L44.6752 166.055H31.7336L29.8426 160.116H14.6554L12.7644 166.055H0L15.3645 123.119ZM26.356 149.189L22.2785 136.302L18.1419 149.189H26.356Z"
            fill="#172554"
          />
          <path
            d="M47.5708 123.119H64.59C77.177 123.119 86.573 132.324 86.573 144.616C86.573 156.85 77.177 166.114 64.59 166.114H47.5708V123.119ZM59.3897 134.343V154.89H64.3536C70.0857 154.89 74.4587 150.496 74.4587 144.616C74.4587 138.737 70.1448 134.343 64.3536 134.343H59.3897Z"
            fill="#172554"
          />
          <path
            d="M100.637 123.119H114.584L129.948 166.055H117.007L115.116 160.116H99.9283L98.0373 166.055H85.273L100.637 123.119ZM111.629 149.189L107.551 136.302L103.415 149.189H111.629Z"
            fill="#172554"
          />
          <path
            d="M132.844 123.119H147.499L157.486 142.954L167.473 123.119H181.892V166.055H170.073V141.172L160.736 159.819H154.059L144.663 141.172V166.055H132.844V123.119Z"
            fill="#172554"
          />
          <path
            d="M194.184 137.609H187.979V134.283H194.184V130.008C194.184 124.307 197.788 120.862 203.875 120.862H207.598V124.247H204.171C200.27 124.247 197.788 126.682 197.788 130.483V134.343H207.007V137.668H197.788V166.114H194.184V137.609Z"
            fill="#172554"
          />
          <path
            d="M217.939 157.206V137.609H211.735V134.283H215.871C217.289 134.283 218.058 133.452 218.058 132.086V125.257H221.544V134.283H230.763V137.609H221.544V156.672C221.544 160.354 223.967 162.729 227.749 162.729H230.763V166.114H227.217C221.426 166.055 217.939 162.729 217.939 157.206Z"
            fill="#172554"
          />
          <path
            d="M235.727 150.199C235.727 140.756 242.582 133.571 251.741 133.571C257.828 133.571 262.733 136.659 265.392 141.588V121.219H268.997V166.055H265.392V158.75C262.733 163.679 257.828 166.767 251.741 166.767C242.582 166.827 235.727 159.641 235.727 150.199ZM265.392 150.199C265.392 142.716 259.837 137.075 252.391 137.075C245.005 137.075 239.45 142.716 239.45 150.199C239.45 157.681 245.005 163.323 252.391 163.323C259.837 163.323 265.392 157.681 265.392 150.199Z"
            fill="#172554"
          />
          <path
            d="M267.401 103.225C267.342 103.225 267.342 103.225 267.401 103.225C227.395 102.69 223.967 96.6331 197.552 49.5999C195.129 45.2648 192.352 40.2764 189.338 35.0505C179.115 17.0568 170.428 8.08958 163.514 8.32712C154.827 8.68343 147.913 23.5298 140.585 39.2669L139.935 40.6921C134.853 51.5596 129.535 57.0231 123.625 57.3794C114.111 57.9139 106.251 45.0272 98.6874 32.5563C98.0373 31.4874 97.3873 30.3591 96.6782 29.2901C92.6598 22.7577 89.4096 19.7885 86.3958 19.9666C78.9499 20.3823 68.8448 40.1576 61.9308 55.0634C51.0574 78.3424 35.5748 89.9819 24.5241 95.6235C12.4689 101.8 3.01385 102.453 2.65929 102.453C1.35921 102.512 0.236425 101.503 0.177331 100.196C0.118237 98.8897 1.12284 97.7614 2.42291 97.702C2.65929 97.702 11.5234 97.0488 22.6331 91.2884C32.9746 85.9437 47.4527 74.9574 57.676 53.0443C70.204 26.3209 78.1817 15.6909 86.1594 15.2752C92.5416 14.9189 97.2691 21.2731 100.756 26.8553C101.406 27.9243 102.115 29.0526 102.765 30.1215C109.324 40.8703 116.711 53.0443 123.448 52.6879C127.407 52.4504 131.544 47.759 135.739 38.7324L136.389 37.3072C144.308 20.2042 151.813 4.11076 163.396 3.63568C172.437 3.27937 182.01 12.4841 193.474 32.7345C196.488 38.0198 199.266 42.9488 201.689 47.2839C228.045 94.1983 230.172 97.9989 267.46 98.5334C268.76 98.5334 269.824 99.6023 269.765 100.968C269.706 102.215 268.642 103.225 267.401 103.225Z"
            fill="url(#paint0_linear_13780_49752)"
          />
          <path
            d="M267.401 103.225C257.296 103.225 247.487 95.6235 235.077 86.0031C232.772 84.2216 230.349 82.3806 227.867 80.4803C212.089 68.6032 186.915 65.2776 168.773 86.1219C162.214 93.7232 155.95 97.4645 149.567 97.5833C149.449 97.5833 149.331 97.5833 149.213 97.5833C140.585 97.5833 134.203 90.8133 129.003 85.3499C127.88 84.1622 126.816 83.0339 125.812 82.0837C125.043 81.3711 124.275 80.5991 123.507 79.827C113.638 70.0879 105.129 61.6551 85.3911 81.8461C62.6989 105.006 4.78662 102.512 2.30466 102.393C1.00459 102.334 -8.01527e-06 101.206 0.0590862 99.8993C0.11818 98.5928 1.24097 97.5833 2.54104 97.6426C3.13198 97.6426 60.8079 100.196 82.0228 78.5206C105.069 54.9446 117.184 66.8811 126.875 76.4421C127.643 77.2141 128.412 77.9267 129.121 78.6393C130.185 79.6489 131.307 80.8366 132.43 82.0837C137.217 87.1314 142.594 92.8324 149.213 92.8324C149.272 92.8324 149.39 92.8324 149.449 92.8324C154.295 92.7137 159.613 89.3881 165.168 82.9745C174.387 72.3445 186.206 66.5841 199.325 66.2872C210.257 66.109 221.662 69.8503 230.645 76.6202C233.186 78.5206 235.609 80.4209 237.913 82.2025C249.614 91.229 258.833 98.4146 267.342 98.4146C268.642 98.4146 269.706 99.4836 269.706 100.79C269.765 102.156 268.701 103.225 267.401 103.225Z"
            fill="url(#paint1_linear_13780_49752)"
          />
          <path
            d="M267.283 103.225C254.164 103.225 238.741 102.453 223.494 96.9894C206.002 90.6946 192.056 79.4114 180.887 62.6054C175.273 54.1726 169.778 50.0157 164.932 50.4907C159.85 51.0252 154.768 57.0231 150.927 66.8811C150.395 68.1876 149.922 69.5534 149.39 70.8599C144.84 82.9151 140.171 95.3266 130.657 97.8208C125.043 99.3055 118.72 96.9894 111.452 90.8134C89.705 72.4633 76.9407 71.6913 54.4849 87.5472C30.729 104.294 3.4275 102.512 2.24562 102.394C0.945543 102.275 -0.0590567 101.146 0.0591317 99.8399C0.17732 98.5335 1.30011 97.5239 2.60018 97.6427C2.95475 97.6427 29.488 99.3649 51.7666 83.6277C75.877 66.5842 91.1233 67.4749 114.466 87.1909C120.434 92.2386 125.457 94.2577 129.475 93.1888C136.626 91.3478 141.058 79.5302 144.958 69.1377C145.49 67.7719 145.963 66.406 146.495 65.0995C149.686 56.7856 155.418 46.6901 164.4 45.7399C171.196 44.9679 178.051 49.7781 184.788 59.933C207.362 93.7826 237.795 98.4741 267.283 98.4741H267.342C268.642 98.4741 269.706 99.543 269.706 100.849C269.706 102.156 268.642 103.225 267.342 103.225C267.342 103.225 267.342 103.225 267.283 103.225Z"
            fill="url(#paint2_linear_13780_49752)"
          />
          <path
            d="M193.227 198.767V179.748H202.954C205.454 179.748 207.32 180.209 208.551 181.133C209.783 182.039 210.399 183.235 210.399 184.72C210.399 185.698 210.136 186.559 209.611 187.301C209.104 188.026 208.379 188.605 207.437 189.04C206.514 189.457 205.409 189.665 204.123 189.665L204.666 188.361C206.006 188.361 207.175 188.569 208.171 188.986C209.167 189.384 209.937 189.973 210.481 190.752C211.042 191.513 211.323 192.446 211.323 193.551C211.323 195.199 210.653 196.485 209.312 197.409C207.99 198.315 206.052 198.767 203.498 198.767H193.227ZM198.552 194.882H203.063C203.987 194.882 204.684 194.728 205.155 194.42C205.644 194.094 205.889 193.605 205.889 192.953C205.889 192.301 205.644 191.821 205.155 191.513C204.684 191.187 203.987 191.024 203.063 191.024H198.172V187.301H202.248C203.153 187.301 203.833 187.147 204.286 186.839C204.738 186.531 204.965 186.069 204.965 185.454C204.965 184.838 204.738 184.385 204.286 184.095C203.833 183.787 203.153 183.633 202.248 183.633H198.552V194.882ZM219.249 187.111H228.079V191.132H219.249V187.111ZM219.629 194.61H229.574V198.767H214.304V179.748H229.221V183.905H219.629V194.61ZM236.802 198.767V184.013H230.96V179.748H247.996V184.013H242.182V198.767H236.802ZM246.957 198.767L255.353 179.748H260.651L269.075 198.767H263.477L256.902 182.383H259.021L252.446 198.767H246.957ZM251.549 195.072L252.935 191.105H262.227L263.613 195.072H251.549Z"
            fill="#172554"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_13780_49752"
            x1="-0.0853904"
            y1="85.1905"
            x2="269.744"
            y2="85.1905"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#22A598" />
            <stop offset="1" stop-color="#BEDA38" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_13780_49752"
            x1="0.033104"
            y1="85.4029"
            x2="269.803"
            y2="85.4029"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#9E005D" />
            <stop offset="1" stop-color="#22A598" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_13780_49752"
            x1="0.0895339"
            y1="74.4838"
            x2="269.744"
            y2="74.4838"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F14C3D" />
            <stop offset="1" stop-color="#F1BD22" />
          </linearGradient>
          <clipPath id="clip0_13780_49752">
            <rect width="269.767" height="200" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
};

export default GlobalLoading;
