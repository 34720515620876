import { Theme, alpha } from '@mui/material';
import colorSystem from 'src/scss/_colors-system.module.scss';

import {
  styleOverrideAlert,
  styleOverrideBadge,
  styleOverrideButton,
  styleOverrideButtonGroup,
  styleOverrideCheckbox,
  styleOverrideChip,
  styleOverrideIconButton,
  styleOverrideRadio,
  styleOverrideSelect,
  styleOverrideSwitch,
  styleOverrideTextField,
} from './stylesOverride/index';

export default function componentStyleOverrides(theme: Theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          borderRadius: '4px',
          minWidth: 0,
          ...styleOverrideButton(theme),
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          width: 'fit-content',
          minWidth: 0,
          ...styleOverrideIconButton(theme),
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          ...styleOverrideButtonGroup(theme),
          boxShadow: 'none',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        sizeSmall: {
          padding: '0.25rem',
        },
        root: {
          '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall': {
            fontSize: '1.25rem',
          },
          ...styleOverrideCheckbox(theme),
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: { ...styleOverrideRadio(theme) },
      },
    },
    MuiBadge: {
      styleOverrides: {
        ...styleOverrideBadge(theme),

        root: {},
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
        rounded: {
          borderRadius: `8px`,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: colorSystem.grey900,
          padding: '32px',
        },
        title: {
          fontSize: '1.125rem',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '32px',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '32px',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filled: {
          color: '#fff',
        },
        outlined: {},
        root: {
          alignItems: 'normal',
          ...styleOverrideAlert(theme),
        },
      },
    },
    MuiSpeedDial: {
      styleOverrides: {
        root: {
          '& .MuiSpeedDial-fab': {
            backgroundColor: '#422AFB',
            color: '#6B7280',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          borderBottom: '1px solid',
          borderColor: colorSystem.grey200,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        fontWeight: 500,
        textColorPrimary: {
          '&:hover': {
            color: theme.palette.primary.main,
          },
          '&.Mui-selected': {
            color: theme.palette.primary.main,
          },
        },
        textTransform: 'none',
        root: {
          '&.MuiTab-root': {
            textTransform: 'none',
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          '& .MuiStepIcon-root ': {
            color: theme.palette.text.disabled,
            '&.Mui-active': {
              color: theme.palette.primary.main,
            },
            '&.Mui-completed': {
              color: theme.palette.primary.main,
            },
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.07),
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: '4px 16px',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: colorSystem.grey700,
          minWidth: '36px',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          margin: 0,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: theme.palette.text.secondary,
          '&::placeholder': {
            color: theme.palette.text.secondary,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.divider,
          opacity: 1,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          ...styleOverrideSelect(theme),
        },
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          color: theme.palette.text.disabled,
          '&.Mui-focused': {
            color: theme.palette.text.primary,
          },
          '&.MuiInputLabel-shrink': {
            color: theme.palette.text.primary,
          },
          '&.MuiInputLabel-sizeSmall': {
            fontSize: '0.875rem',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        ...styleOverrideSwitch(theme),
        root: {
          width: 34,
          height: 16,
          padding: 0,
          display: 'flex',
          '&.MuiSwitch-sizeSmall': {
            width: 26,
            height: 12,
          },
          '& .MuiSwitch-thumb': {
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            width: 10,
            height: 10,
            borderRadius: 6,
            transition: theme.transitions.create(['width'], {
              duration: 200,
            }),
          },
          '& .MuiSwitch-switchBase': {
            padding: 3,
            '&.Mui-checked': {
              transform: 'translateX(18px)',
              color: '#fff',
            },
          },
          '& .MuiSwitch-track': {
            borderRadius: theme.shape.borderRadius * 2,
          },
          '& .Mui-checked+.MuiSwitch-track': {
            opacity: 1,
            boxSizing: 'border-box',
          },
          // ---------------Size=small---------------
          '&.MuiSwitch-sizeSmall .MuiSwitch-thumb': {
            width: 8,
            height: 8,
          },
          '&.MuiSwitch-sizeSmall .MuiSwitch-switchBase': {
            padding: 2,
            '&.Mui-checked': {
              transform: 'translateX(14px)',
            },
          },
          '&.MuiSwitch-sizeSmall .Mui-checked+.MuiSwitch-track': {
            opacity: 1,
            boxSizing: 'border-box',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          ...styleOverrideTextField(theme),
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: colorSystem.neutroState300,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        ...styleOverrideChip(theme),
        root: {
          '&.MuiChip-deletable .MuiChip-deleteIcon': {
            color: 'inherit',
          },
          fontWeight: 400,
          fontSize: 12,
          lineHeight: '100%',
        },
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        label: {
          marginTop: 14,
          marginBottom: 14,
        },
      },
    },
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '12px 0 12px 0',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.25rem',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          colorPrimary: {
            color: theme.palette.primary.main,
          },
          colorSuccess: {
            color: theme.palette.success.main,
          },
          colorDanger: {
            color: theme.palette.error.main,
          },
          colorWarning: {
            color: theme.palette.warning.main,
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: { padding: '20px 78px', height: 80 },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiAutocomplete-popupIndicator': { transform: 'none' },
          '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            padding: 7.5,
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeSmall: {
          fontSize: '1rem',
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: 'inherit',
          marginRight: 0,
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
    MuiRating: {
      styleOverrides: {
        root: {
          color: theme.palette.rating.main,
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        tooltip: {
          background: '#000',
        },
        arrow: {
          color: '#000',
        },
      },
    },
  };
}
