import dayjs from 'dayjs';

export const API_URI = process.env.REACT_APP_API_URL;
export const CALENDAR_BOOKING_URL = 'https://tidycal.com/adamftd/30-minute-meeting';

// App localStorage/session keys
export const REFRESH_TOKEN_KEY = 'REFRESH_TOKEN';
export const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN';
export const IP_ADDRESS_KEY = 'IP_ADDRESS_KEY';
export const DEVICE_ID_KEY = 'DEVICE_ID_KEY';
export const SKIP_ASK_PAY_CREDIT = 'SKIP_ASK_PAY_CREDIT';
export const CLOSE_BANNER_BOOKING_CALENDAR = 'CLOSE_BANNER_BOOKING_CALENDAR';

// Config Sidebar
export const DRAWER_WIDTH = 256;
export const DRAWER_WIDTH_CLOSE = 64;
export const HEADER_HEIGHT = 70;

// Constant
export const MAX_TIMES_RETRY_CHECK_IMAGES = 10;
export const MAX_TIMES_RETRY_CHECK_IMAGES_CONTRIBUTE = 20;
export const LENGTH_OTP_VALIDATE = 6;
export const MIN_LENGTH_PASSWORD = 12;
export const MAX_IMAGES_UPLOAD_CONTRIBUTE = 10;
export const MAX_SIZE_IMAGE_UPLOAD = 1048576;
export const LIMIT_GET_LIST = 10;
export const LIMIT_GET_LIST_PEOPLE = 12;
export const LIMIT_GET_LIST_AUTO_COMPLETE = 50;
export const LIMIT_EXPORT_PEOPLE_PER_PAGE = 50;
export const LIMIT_EXPORT_BILLS_OF_LADING_PER_PAGE = 10;
export const LIMIT_GET_LIST_BILLS_OF_LADING = 50;
export const MINUTE_REFETCH_STOCK_PRICE = 5;
export const LINK_DATA_BREACHES = 'https://haveibeenpwned.com/';
export const MARKETING_PAGE = 'https://adamftd.com';
export const LINK_SUPPORT = 'https://support.adamftd.com';
export const PRODUCT_ROADMAP = 'https://roadmap.adamftd.com';
export const PRIVACY = 'https://adamftd.com/legal-policies/user-privacy-policy';
export const TERMS = 'https://adamftd.com/legal-policies/terms-of-use/';
export const COOKIES = 'https://adamftd.com/legal-policies/cookies-policy/';
export const REFUND_POLICY = 'https://adamftd.com/legal-policies/refunds/';
export const LINK_DOWNLOAD_BROCHURE = 'https://j4t6.c1.e2-10.dev/images/front-end/documents/ADAMftd-brochure.pdf';
export const LINK_GLEIF_SOURCE = 'https://en.wikipedia.org/wiki/Legal_Entity_Identifier';
export const LEVEL_PRODUCT = {
  HS_CODE: 6,
  NATIONAL_CODE: 8,
};
export const EmptyText = '--';
export const UnknownText = 'Unknown';
export const WEBSITE_PROTOCOL = {
  HTTP: 'http://',
  HTTPS: 'https://',
};
export const FORMAT_DATE_TIME = {
  FULL_DATE: 'HH:mm DD/MM/YYYY',
  TIME: 'HH:mm',
  DATE_MONTH: 'DD-MM',
  FULL_ONLY_DATE: 'DD/MM/YYYY',
  H_MM_A: 'h:mmA',
  UTC: 'DD-MM-YYYYTHH:mm:ssZ',
  DD_MM_YYYY_HH_MM_A: 'DD-MM-YYYY hh:mm A',
  yyyy_mm_dd: 'YYYY-MM-DD',
  MONTH_YEAR: 'MM/YYYY',
  LONG_MONTH_YEAR: 'MMMM YYYY',
  MMM_DD_YYYY: 'MMM DD, YYYY',
  yyyy_mm: 'YYYY-MM',
  LONG_MONTH_DAY_YEAR: 'MMMM DD, YYYY',
  yyyymmdd: 'YYYYMMDD',
  DD_MM_YYYY: 'DD-MM-YYYY',
  FULL_YEAR: 'YYYY',
  FULL_MONTH: 'MMMM',
  SHORT_MONTH: 'MMM',
};
export const COUNTRIES_HAVE_STATE = ['US'];
export const MAX_LENGTH_CONTENT = {
  COMPANY_INFORMATION: 400,
  PEOPLE_INFORMATION: 400,
  COMPANY_RELATED: 1000,
};
export const ACCEPT_TYPE_IMAGE = ['image/png', 'image/jpeg', 'image/jpg'];

export const TRADE_DATA_MAXIMUM_SUPPORTED_DATE_RANGE_MONTHS = 5 * 12;

export const TRADE_DATA_DEFAULT_DATE_RANGE_YEARS = 2;

export const TRADE_DATA_CACHE_TIME = 5 * 60 * 1000; // 5 minutes

export const TRADE_DATA_INITIAL_DATA_INVALIDATE_TIME = dayjs().subtract(TRADE_DATA_CACHE_TIME, 'millisecond').valueOf();
